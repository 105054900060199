




















































import './styles/ConstructDocumentationPopup.scss';
import './styles/ConstructDocumentationPopupAdaptive.scss';
import { Component, Watch, Vue } from 'vue-property-decorator';
import { IConstructDocument } from '@store/modules/work-list/interfaces/Interfaces';
import { namespace } from 'vuex-class';
import { formatDate } from '@utility/formatDate';
import { PopupActions } from '@store/Popup';
import { IWorkListPopupsState } from '@store/modules/work-list/modules/popups/Interfaces';
import { WorkListMutations } from '@store/modules/work-list/Types';
import { ITogglePopupPayload } from '@/interfaces/ITogglePopupPayload';

const NSWorkListPopups = namespace('storeWorkList/storeWorkListPopups');
const NSWorkList = namespace('storeWorkList');

@Component({
    name: 'ConstructDocumentationPopup',

    components: {
        BasePopup: () => import('@components/popups/BasePopup.vue'),
        BaseButton: () => import('@components/buttons/BaseButton.vue'),
    },
})
export default class ConstructDocumentationPopup extends Vue {
    $refs!: { basePopup: any }

    @NSWorkListPopups.Getter('constructDocumentationPopupToggler') constructDocumentationPopupToggler!: boolean;

    @NSWorkListPopups.Action(PopupActions.A_TOGGLE_POPUP)
    togglePopup!: (
        payload: ITogglePopupPayload<IWorkListPopupsState>,
    ) => void;

    @NSWorkList.Getter('actionOnConstruct') actionOnConstruct!: IConstructDocument;

    @NSWorkList.Mutation(WorkListMutations.M_SET_VIEWING_DOCUMENT)
    setViewingDocument!: (document: IConstructDocument) => void;

    @Watch('constructDocumentationPopupToggler')
    watchPopupOpen() {
        if (this.constructDocumentationPopupToggler) this.$refs.basePopup.openPopup();
        else this.$refs.basePopup.closePopup();
    }

    formattedDate(date) {
        return formatDate({ date: new Date(date)});
    }

    viewDocumentFiles(document: IConstructDocument) {
        this.setViewingDocument(document);
        this.togglePopup({
            popupToggler: 'documentFilesPopupToggler',
            state: true,
        });
    }

    closePopup() {
        this.togglePopup({
            popupToggler: 'constructDocumentationPopupToggler',
            state: false,
        });
    }
}
